<form (submit)="onSignup(signUpForm)" #signUpForm="ngForm">
    <div class="container">
        <label>Username : </label>
        <input name="email" ngModel type="email" placeholder="E-Mail" #emailInput="ngModel" required email>
        <label>Password : </label>
        <input type="password" name="password" ngModel placeholder="Password" #passwordInput="ngModel" required>
        <button type="submit">signUp</button>
        <button type="button" class="cancelbtn"> Cancel</button>
        Forgot <a href="#"> password? </a>
    </div>
</form>