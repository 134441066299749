import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

import { AuthData } from "./auth-data.model";
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ 'content-Type': 'application/json' })
}
const BACKEND_URL = environment.apiUrl + "/admin/";

@Injectable({ providedIn: "root" })
export class AuthService {
  private isAuthenticated = false;
  private token: string;
  private authStatusListener = new Subject<boolean>();

  constructor(private http: HttpClient, private router: Router) {}

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  createUser(email: string, password: string) {
    const authData: AuthData = { email: email, password: password };
    this.http
      .post("#href", authData)
      .subscribe(response => {
        console.log("From Service");
        console.log(response);
      });
  }

  login(email: string, password: string) {
    const authData: AuthData = { email: email, password: password };
    this.http
      .post<{ token: string }>("http://localhost:3000/api/admin/login", authData)
      .subscribe(response => {
        console.log(response);
        // const token = response.token;
        // this.token = token;
        // if (token) {
        //   this.isAuthenticated = true;
        //   this.authStatusListener.next(true);
        //   this.router.navigate(['/']);
        // }
      });
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.router.navigate(['/']);
  }
}
