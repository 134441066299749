<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo logo-text" href="#" (click)="navigateHome()">Lucky Vegetables<span></span></a>
  </div>
  <nb-select style="margin-left: 60px;" [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>  -->
    <!-- <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action">
      <button nbButton outline status="warning" (click)="onLogout()">Logout</button>
    </nb-action>
  </nb-actions>
</div>