import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { NbLoginComponent } from '@nebular/auth';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends NbLoginComponent {
  //isLoading = false;

  // constructor(public authService: AuthService,
  //   private router: Router,
  // ) {}

  // onLogin(form: NgForm) {
  //   if (form.invalid) {
  //     return;
  //   }
  //   this.isLoading = true;
  //   this.authService.login(form.value.email, form.value.password);
  // }

  // goToHome() {
  //   //this.menuService.navigateHome();
  //   this.router.navigate(['/']);
  // }
}
