import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthRoutingModule } from "./auth-routing.module";
import { ThemeModule } from "../@theme/theme.module";
import { NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy } from "@nebular/auth";
import { AuthService } from "./auth.service";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { NbAlertModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbInputModule, NbLayoutModule, NbSpinnerModule } from "@nebular/theme";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../../environments/environment";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        AuthRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        ThemeModule,
        NbLayoutModule,
        NbCardModule,
        NbButtonModule,
        NbInputModule,
        NbSpinnerModule,
        NbCheckboxModule,
        NbAlertModule,
        NbAuthModule.forRoot({
            strategies: [
                NbPasswordAuthStrategy.setup({
                    name: 'email',
                    baseEndpoint: environment.apiUrl,
                    login: {
                        endpoint: '/admin/login',
                        redirect: {
                            success: '/pages/dashboard/',
                            failure: null,
                        }
                    },
                    register: {
                        endpoint: '/admin/signup',
                        redirect: {
                            success: '/auth/login/',
                            failure: null,
                        }
                    },
                    token: {
                        class: NbAuthJWTToken,
                        key: 'token',
                    },
                }),
            ],
            forms: {},
        }),
    ],
    providers: [
        AuthService
    ],
    declarations: [LoginComponent, SignupComponent],
    exports: [NbAuthModule]
})
export class AuthModule { }