import { RouterModule, Routes } from "@angular/router";
import { NbAuthComponent, NbLoginComponent, NbLogoutComponent, NbRegisterComponent, NbRequestPasswordComponent, NbResetPasswordComponent } from "@nebular/auth";
import { LoginComponent } from "./login/login.component";
import { NgModule } from "@angular/core";
import { NotFoundComponent } from "../pages/miscellaneous/not-found/not-found.component";

export const routes: Routes = [
    {
        path: '',
        component: NbAuthComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
            },
            {
                path: 'login',
                component: LoginComponent,
                // canActivate: [AuthGuard],
            },
            {
                path: 'register',
                //component: NbRegisterComponent,
                component: NotFoundComponent,
            },
            {
                path: 'logout',
                component: NbLogoutComponent,
            },
            // {
            //     path: 'request-password',
            //     component: NbRequestPasswordComponent,
            // },
            {
                path: 'reset-password',
                component: NotFoundComponent,
            }
        ]
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: '**',
        component: LoginComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}